import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://web-api-sgp.piebarometre.digital/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  },
})

export default {
  getAssocitations () {
    return apiClient.get('api/request-parametre-agr/list-projet-associer-agr')
  },
  createAssocitation (data) {
   var formData = new FormData()
    formData.append('id_parametrage_agr', data.id_parametrage_agr)
    formData.append('id_projets', data.id_projet)
    return apiClient.post('api/request-parametre-agr/add-projet-associer-agr', formData)
  },
  updateAssocitation (data) {
    var formData = new FormData()
    formData.append('id_parametrage_agr', data.id_parametrage_agr)
    formData.append('id_projets', data.id_projet)
    return apiClient.post(`api/request-parametre-agr/update-projet-associer-agr/${data.id}`, formData)
  },
  deleteAssocitation (data) {
    return apiClient.post(`api/request-parametre-agr/delete-projet-associer-agr/${data.id}`)
  },
}
