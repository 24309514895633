import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://web-api-sgp.piebarometre.digital/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  },
})

export default {
  getParamsAGR () {
    return apiClient.get('api/request-parametre-agr/get-parametrage')
  },
  createParamsAGR (ParamsAGR) {
    const formData = new FormData()
    formData.append('libelle_parametrage_agrs', ParamsAGR.libelle_parametrage_agrs)
    return apiClient.post('api/request-parametre-agr/add-parametrage', formData)
  },

  updateParamsAGR (ParamsAGR) {
    const formData = new FormData()
    formData.append('libelle_parametrage_agrs', ParamsAGR.libelle_parametrage_agrs)
     return apiClient.post(`api/request-parametre-agr/update-parametrage/${ParamsAGR.id}`, formData)
  },

  deleteParamsAGR (ParamsAGR) {
    return apiClient.post(`api/request-parametre-agr/delete-parametrage/${ParamsAGR.id}`)
 },
}
